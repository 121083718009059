.MuiCircularProgress-indeterminate {
  -webkit-animation: MuiCircularProgress-keyframes-circular-rotate 1.4s linear
    infinite;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#loading {
  font-family: 'Roboto', sans-serif;
}

#loading a {
  color: #314f61;
  text-decoration: none;
}

#loading a:hover {
  text-decoration: underline;
}
